import * as React from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"

export function AnimatedBlock(props) {
    const [isVisible, setIsVisible] = React.useState(false)

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true)
        }, 5000)
        return () => clearTimeout(timer)
    }, [])

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={isVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
            style={{
                padding: 16,
                borderRadius: 8,
                backgroundColor: props.isActive ? "#f0f0ff" : "#f0f0f0",
                marginBottom: 16,
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 8,
                }}
            >
                <motion.div
                    initial={{ rotate: 0 }}
                    animate={{ rotate: 360 }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        ease: "linear",
                    }}
                >
                    {props.icon}
                </motion.div>
                <h3
                    style={{
                        marginLeft: 12,
                        color: props.isActive ? "#5050ff" : "#333",
                    }}
                >
                    {props.heading}
                </h3>
            </div>
            {props.isActive && <p>{props.content}</p>}
        </motion.div>
    )
}

addPropertyControls(AnimatedBlock, {
    heading: {
        type: ControlType.String,
        title: "Heading",
        defaultValue: "Block Heading",
    },
    content: {
        type: ControlType.String,
        title: "Content",
        defaultValue: "Block content goes here.",
    },
    icon: {
        type: ControlType.ComponentInstance,
        title: "Icon",
    },
    isActive: {
        type: ControlType.Boolean,
        title: "Is Active",
        defaultValue: false,
    },
})
